<template>
  <div id="reports">
    <Hero/>
    <div class="reports-section">
      <h1>Reports</h1>
      <ul>
         <li v-for="location in locations" :key="location" v-bind:class="{ isActive: location === active }" v-on:click="filter(location)">{{location}}</li>
      </ul>
      <div class="reports">
        <div class="report" v-for="report in tempReports" :key="report.report_id">
          <ReportCard :name="report.location_name" :id="report.report_id" :flow="report.flow" :date="report.date" :rating="report.rating" :drainage="report.drainage_name"/>
        </div>
      </div>
      <router-link class="see-more" to="/destinations">See Destinations > </router-link>
    </div>
    <FollowUs/>
    <BookNow/>
  </div>
</template>

<script>
import api from '@/api'
import Hero from '@/components/Hero.vue'
import ReportCard from '@/components/ReportCard.vue'
import FollowUs from '@/components/FollowUs.vue'
import BookNow from '@/components/BookNow.vue'
export default {
  components: { 
    Hero, 
    ReportCard,
    FollowUs,
    BookNow
  },
  data() {
    return {
      reports: [],
      tempReports: [],
      active: "All", 
      locations: ["All", "Front Range", "South Platte", "Colorado Favorites"]
    };
  },
  async created () {
    const getReports = await api.get('/reports')
    this.reports = this.sort(getReports.reports)
    this.filter('All')
  }, 
  methods: {
    sort(reports) {
      return reports.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    filter(active) {
      this.active = active
      let tempReports = this.reports
    
      if (this.active !== "All") {
        tempReports = tempReports.filter((item) => {
          return (item.drainage_name === this.active)
        })
      }
      return this.tempReports = tempReports
    }
  }
}
</script>


<style lang="scss">
#reports {
  .reports-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
      padding-bottom: 10px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      width: 75%;
      padding-left: 0;
    }
    li:hover {
      cursor: pointer;
    }
    .isActive {
      color: #3C6E71;
      font-weight: 600;
      text-decoration: underline;
    }
    .report-title {
      margin-top: 50px;
    }
    .reports {
      display: flex; 
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .report {
      width: 30%;
      min-width: 300px;
    }
  }
  @media only screen and (max-width: 500px) {
    .reports-section {
      width: 90%;
      margin: auto;
      .report {
        width: 100%;
      }
      ul {
        width: 100%;
        flex-direction: column;
      }
    }
  }
}
</style>